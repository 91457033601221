import React from "react";
import "../styles/Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="left">
        <div className="inquiries">
          <p className="title">General Inquiries</p>
          <p
            className="link underline"
            onClick={() => {
              var link =
                "mailto:contact@katapultmakerspace.com" +
                "&subject=" +
                encodeURIComponent("Katapult Makerspace enquiry");

              window.location.href = link;
            }}
          >
            contact@katapultmakerspace.com
          </p>
          <p
            className="link"
            onClick={() => {
              window.location.href = "tel:+17174322352";
            }}
          >
            +1 717-432-2352
          </p>
        </div>
        <div className="location">
          <p className="title">Katapult Makerspace HQ</p>
          <p
            className="location"
            onClick={() => {
              window.location.href = "https://g.page/KatapultMakerspace?share";
            }}
          >
            17 S. Baltimore St. <br />
            Dillsburg, PA 17019M <br />
            United States
          </p>
        </div>
      </div>

      <div className="right">
        <div className="social">
          <b>
            <p
              className="link"
              onClick={() => {
                window.location.href =
                  "https://www.facebook.com/katapultmakerspace/";
              }}
            >
              Facebook
            </p>
            <p
              className="link"
              onClick={() => {
                window.location.href =
                  "https://www.instagram.com/katapultmakerspace/";
              }}
            >
              Instagram
            </p>
          </b>
        </div>
      </div>
    </div>
  );
};

export default Footer;
