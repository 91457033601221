import React from "react";
import logo from "../assets/logo.png";
import "../styles/Navbar.scss";
import Fade from "react-reveal";
import animateScrollTo from "animated-scroll-to";
import { slide as Menu } from "react-burger-menu";

const Navbar = () => {
  return (
    <div className="navbar">
      {/* <Menu>
        <a id="home" className="menu-item">
          Home
        </a>
        <a id="about" className="menu-item">
          About
        </a>
        <a id="contact" className="menu-item">
          Contact
        </a>
      </Menu> */}
      <Fade>
        <img src={logo} className="logo" />
      </Fade>

      <Fade top cascade>
        <div className="links">
          <p
            onClick={() => {
              animateScrollTo(document.querySelector(".whats-in-a-makebox"));
            }}
          >
            What's Inside
          </p>
          <p
            onClick={() => {
              animateScrollTo(document.querySelector(".team"));
            }}
          >
            Team
          </p>
          <p
            onClick={() => {
              animateScrollTo(document.querySelector(".makerspace"));
            }}
          >
            Makerspace
          </p>
          {/* <p onClick={()=>{
            animateScrollTo(document.querySelector('.whats-in-a-makebox'))
          }}>Contact</p> */}
        </div>
      </Fade>
    </div>
  );
};

export default Navbar;
