import React from "react";
import katapult from "../assets/katapult-front.jpg";
import "../styles/Makerspace.scss";
import Fade from "react-reveal/Fade";

const Makerspace = () => {
  return (
    <Fade>
      <div className="makerspace">
        {/* <img src={katapult} className="image" /> */}
        <div className="text">
          <h1>
            <span className="blue-text">Katapult</span> Makerspace
          </h1>
          <h2>The home of MakeBox</h2>
          <p>
            We created a makerspace from the old downtown library and opened it
            to the community to engage, entertain, and educate the public about
            Making. We host weekly GameNights and MakeNights where the public
            can come and join us to play table-top games, hop in the Virtual
            Reality, and use any of our equipment!
          </p>
        </div>
      </div>
    </Fade>
  );
};

export default Makerspace;
