import React from "react";
import "../styles/WhatsInAMakebox.scss";
import box from "../assets/box.svg";
import gear from "../assets/gear-icon.svg";
import book from "../assets/book-icon.svg";
import magnet from "../assets/magnet-icon.svg";
import Fade from "react-reveal";

const WhatsInAMakebox = () => {
  return (
    <div className="whats-in-a-makebox">
      <Fade bottom>
        <img src={box} className="box" />
      </Fade>
      <Fade bottom>
        <h1>
          What's In a <span className="blue-text">Makebox</span>?
        </h1>
      </Fade>

      <div className="makebox-features">
        <div className="feature">
          <img src={gear} className="gear" />
          <h2>
            High-tech
            <br />
            components
          </h2>
          <p>
            Such as N52 neodymium magnets, proto-boards, and integrated circuit
            chips
          </p>
        </div>
        <Fade cascade bottom>
          <div className="feature">
            <img src={magnet} />
            <h2>
              An engaging
              <br />
              project
            </h2>
            <p>
              In the discipline of magnetics, electrodynamics, electronics, and
              more!
            </p>
          </div>

          <div className="feature">
            <img src={book} />
            <h2>
              Step-by-step
              <br />
              instructions
            </h2>
            <p>With space for questions and observations</p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default WhatsInAMakebox;
