import React, { useState } from "react";
import "../styles/SignUp.scss";
import TextBox from "../components/TextBox";
import graphic from "../assets/sign-up-graphic.svg";
import card from "../assets/card.svg";

const SignUp = () => {
  const [showBillingSection, setShowBillingSection] = useState(false);

  const BillingCheckbox = () => {
    return (
      <div
        className="checkbox-area"
        onClick={() => {
          setShowBillingSection(!showBillingSection);
        }}
      >
        <input
          type="checkbox"
          id="billinginfo"
          checked={!showBillingSection}
        ></input>
        <p>Billing info the same</p>
      </div>
    );
  };

  return (
    <div className="sign-up">
      <div className="graphic">
        <img src={graphic} />
      </div>
      <div className="text">
        <h1>Sign up for Makebox!</h1>
        <div className="cost">
          <p>
            <span className="large">$24</span>/month + Free shipping
          </p>

          <div className="form">
            <div className="section">
              <h2 className="title">Contact</h2>
              <TextBox title={"First name"} id={"firstname"} />
              <TextBox title={"Last name"} id={"lastname"} />
              <TextBox title={"Email"} id={"email"} lineBreak={true} />
            </div>

            <div className="section">
              <h2 className="title">Shipping Info</h2>
              <TextBox
                title={"Address"}
                id={"address"}
                large={true}
                lineBreak={true}
              />
              <TextBox title={"City"} id={"city"} />
              <TextBox title={"State"} id={"state"} small={true} />
              <TextBox title={"Zip Code"} id={"zipcode"} />
              <BillingCheckbox />
            </div>

            {showBillingSection ? (
              <div className="section">
                <h2 className="title">Billing Info</h2>
                <TextBox
                  title={"Address"}
                  id={"address"}
                  large={true}
                  lineBreak={true}
                />
                <TextBox title={"City"} id={"city"} />
                <TextBox title={"State"} id={"state"} small={true} />
                <TextBox title={"Zip Code"} id={"zipcode"} />
              </div>
            ) : null}

            <div className="section" style={{ marginBottom: 2 + "em" }}>
              <h2 className="title">Credit/Debit Card</h2>
              <img src={card} className="credit-card" />
              <TextBox
                title={"Card Number"}
                id={"cardnumber"}
                large={true}
                lineBreak={true}
              />
              <TextBox title={"Expiry Date"} id={"expirydate"} />
              <TextBox title={"CVC"} id={"cvc"} small={true} />
              <TextBox
                title={"Promo Code (optional)"}
                id={"promocode"}
                lineBreak={true}
                promo={true}
              />
            </div>
          </div>

          <div
            className="checkbox-area"
            onClick={() => {
              document.getElementById(
                "termsandconditions"
              ).checked = !document.getElementById("termsandconditions")
                .checked;
            }}
          >
            <input type="checkbox" id="termsandconditions"></input>
            <p>
              I understand and agree that I will be billed monthly and that I
              can cancel at any time by contacting Katapult at 717-432-0716
            </p>
          </div>

          <div className="button">
            <h2>Sign Up</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
