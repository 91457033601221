import React from "react";
import "./styles/App.scss";
import Navbar from "./components/Navbar";
import Featured from "./components/Featured";
import Footer from "./components/Footer";
import LandingPage from "./views/LandingPage";
import WhatsInAMakebox from "./views/WhatsInAMakebox";
import Makerspace from "./views/Makerspace";
import Team from "./views/Team";
import SignUp from "./views/SignUp";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
        <LandingPage />
        <Featured />
        <WhatsInAMakebox />
        <Team />
        <Makerspace />
        <SignUp />
        <Footer />
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
