import React from "react";
import "../styles/Team.scss";
import jeremiah from "../assets/jeremiah.jpg";
import person from "../assets/person.jpg";
import blue from "../assets/blue-gradient.svg";
import orange from "../assets/orange-gradient.svg";
import Fade from "react-reveal/Fade";

const Team = () => {
  return (
    <div className="team">
      <Fade top>
        {" "}
        <h1>
          The Makebox <span className="blue-text">Team</span>
        </h1>
      </Fade>

      <Fade>
        <div className="profiles">
          <div className="profile">
            <img src={jeremiah} />
            <div className="text">
              <h2>Jeremiah Stonge</h2>
              <p>
                Jeremiah is a Professional Engineer, homeschool parent and
                educator, and President of Katapult Engineering in Dillsburg,
                PA. MakeBox arose from his physics class experiments and the
                desire to grow and develop STEM talent in Central Pennsylvania.
              </p>
            </div>
          </div>
          <div className="profile">
            <img src={person} />
            <div className="text">
              <h2>Ben Batdorf</h2>
              <p>
                Ben Batdorf is a Marketing Communications professional with 10
                years of corporate communications experience and 2 years of
                television broadcast news experience. He develops and delivers
                creative, clear and concise messaging to engage employees and
                achieve business and revenue goals.
              </p>
            </div>
          </div>
        </div>
      </Fade>

      {/* <div className="gradient"> */}
      <img src={blue} className="blue"></img>
      <img src={orange} className="orange"></img>
      {/* </div> */}
    </div>
  );
};

export default Team;
