import React from "react";
import "../styles/TextBox.scss";

const TextBox = ({
  title,
  id,
  large = false,
  small = false,
  lineBreak = false,
  promo = false,
}) => {
  let className = "";

  if (large) className = "large textbox";
  else if (small) className = "small textbox";
  else className = "textbox";

  return (
    <div className={lineBreak ? "textbox-div break" : "textbox-div"}>
      <p className="title">{title}</p>
      {promo ? (
        <div className="promo">
          <input type="text" id={id} className={className}></input>
          <p id="verify-code">Verify code</p>
        </div>
      ) : (
        <input type="text" id={id} className={className}></input>
      )}
    </div>
  );
};

export default TextBox;
