import React from "react";
import "../styles/LandingPage.scss";
import graphic from "../assets/landing-page-graphic.svg";
import blue from "../assets/blue-gradient.svg";
import orange from "../assets/orange-gradient.svg";
import Fade from "react-reveal/Fade";
import { Parallax } from "react-scroll-parallax";
import animateScrollTo from "animated-scroll-to";
import Axios from "axios";

// let url = "https://connect.squareupsandbox.com/v2/payments";

// let body = {
//   amount_money: {
//     amount: 29,
//     currency: "USD",
//   },
//   idempotency_key: "1d38f690-3955-46f4-9e03-8f8d83899de0",
//   source_id: "cnon:card-nonce-ok",
// };

// Axios.post(url, body, {
//   headers: {
//     Authorization: `Bearer EAAAEAA5AE50MdkLgXnYmUhE3u37KubhTsxINpATpQ3NYasCW3e5rv7E0iewOl4j`,
//     idempotency_key: "3c70a0a1-3eaf-4784-a5d5-154ee6e9bd23",
//   },
// });

// Axios.post(url);

const LandingPage = () => {
  return (
    <React.Fragment>
      <div className="landing-page">
        <Fade>
          <Parallax y={[-60, 60]}>
            <div className="text">
              <h1>MakeBox</h1>
              <p>
                High-tech STEM projects, delivered <br></br>right to your door.
              </p>
              <div
                className="button"
                onClick={() => {
                  animateScrollTo(document.querySelector(".sign-up"));
                }}
              >
                <p>Subscribe</p>
              </div>
            </div>
          </Parallax>
        </Fade>
      </div>
      <Fade right>
        <div className="landing-page-graphic">
          <img src={graphic}></img>
        </div>
      </Fade>
      <div className="gradient">
        <img src={blue} className="blue"></img>

        <img src={orange} className="orange"></img>
      </div>{" "}
    </React.Fragment>
  );
};

export default LandingPage;
