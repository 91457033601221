import React from "react";
import "../styles/Featured.scss";
import bricks from "../assets/bricks-4-kidz.svg";
import dillsburg from "../assets/dillsburg-banner.svg";
import girl from "../assets/girl-scouts.svg";
import good_day from "../assets/good-day.svg";
import Fade from "react-reveal";

const Featured = () => {
  return (
    <Fade>
      <div className="featured">
        <p>Featured on</p>
        <div className="images">
          <img src={bricks} />
          <img src={dillsburg} />
          <img src={girl} />
          <img src={good_day} />
        </div>
      </div>
    </Fade>
  );
};

export default Featured;
